<template>
    <div class="m-address-cascader">
        <div class="select-container">
            <el-popover
                v-model="visible"
                popper-class="del-popper-class"
                placement="bottom-start"
                width="480"
                :disabled="disabled"
                :trigger="triggerEvent"
                @show="showClick"
                @hide="show = false;">
                <div class="address-tab-container">
                    <el-tabs v-model="activeName" type="card" ref="tabs">
                        <el-tab-pane v-if="hotData.length" label="热门省市" :key="0" name="0">
                            <div class="ulSorll">
                                <ul>
                                    <li v-for="(item) in hotData" :key="item.label">
                                        <span :class="{ active: item.leaf }" @click="hotCityClick(item)">{{item.label}}</span>
                                    </li>
                                </ul>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane v-for="(item, index) in showTabItem" :key="hotData.length ? index + 1 : index" :label="item.label" :name="String(hotData.length ? index + 1 : index)">
                            <div class="ulSorll">
                                <ul>
                                    <li v-if="showNoChoiceBtn">
                                        <span class="Cactive" @click="confirmSuccess(1)">暂不选择</span>
                                    </li>
                                    <li v-for="(listitem, index) in item.dataArr" :key="index">
                                        <span :class="{ active: listitem.leaf }" :title="listitem.label.length > 6 ? listitem.label : ''"
                                            @click="nextLevel(listitem, item.name)">{{listitem.label}}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="footer-btn" v-if="item.sumMeuber">
                                <div class="right-btn" style="float: right;">
                                    <el-button @click="visible = false">取消</el-button>
                                    <el-button type="primary" @click="confirmSuccess(1)">确认</el-button>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <el-input
                    @blur="show=false"
                    :suffix-icon="icon"
                    slot="reference"
                    class="del-input-class"
                    :placeholder="placeholder"
                    :clearable="clearable"
                    v-model="inputVal"
                    :disabled="disabled"
                    @clear='visibleChange'>
                </el-input>
            </el-popover>
        </div>
    </div>
</template>
<script>
import _ from 'lodash';
import { getAreaApi } from '../api.js';
import { MILLISECOND, SIXTY, TIMEOFDAY } from '@/components/utils/constant';
const AREA_TYPE = ['PLACE_PROVINCE', 'PLACE_CITY', 'PLACE_DISTRICT', 'PLACE_STREET'];
const PROVINCE_DEFAULT_PMCODE = '100000'; // 省的 ebplParentPmCode
// 3 5 7 10 对应省、市、县镇、街截取的长度
const PROVINCE = 3;
const CITY = 5;
const DISTRICT = 7;
const COUNTRY = 10;
export default {
    props: {
        value: {
            type: [Array, String],
            defalut() {
                return [];
            }
        },
        basicProps: {
            type: Object,
            default() {
                return {};
            }
        },
        minLayers: {
            type: Number,
            default() {
                return 0;
            }
        },
        placeholder: String,
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        },
        clearable: {
            type: Boolean,
            default() {
                return true;
            }
        },
        triggerEvent: {
            type: String,
            default() {
                return 'click';
            }
        },
        // 地址选择器缓存时效（毫秒）
        difference: {
            type: Number,
            default() {
                return MILLISECOND * SIXTY * SIXTY * TIMEOFDAY; // 默认24小时
            }
        },
        hotData: {
            type: Array,
            default() {
                return [];
            }
        },
        showNoChoice: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            LEAF_INDEX: 4,
            addressArr: [],
            showbtn: true,
            AREA_MAP: new Map(),
            inputVal: '',
            visible: false,
            show: false,
            activeName: '0',
            checkActive: 0,
            saveSelectDate: [],
            labelName: '请选择',
            hotObj: {},
            tabObj: {
                label: '请选择',
                name: '0',
                tabPane: true,
                sumMeuber: false,
                dataArr: []
            },
            tabArr: [
                {
                    label: '请选择',
                    name: '0',
                    tabPane: true,
                    sumMeuber: false,
                    dataArr: []
                }
            ],
            addressOptions: [],
            addressLabel: []
        };
    },
    methods: {
        optionsFormat(item, isLeaf) {
            return {
                label: item.ebplNameCn,
                value: item.ebplCode,
                ebPCode: item.ebplParentPmCode,
                leaf: isLeaf
            };
        },
        //
        getLazyLoad(data, levNum) {
            const _this = this;
            const level = levNum;
            const params = {
                ebplType: AREA_TYPE[level]
            };
            if (level === 0) {
                params.ebplParentPmCode = PROVINCE_DEFAULT_PMCODE;
            } else {
                params.ebplParentPmCode = data.value;
            }
            getAreaApi(params, _this.difference).then((res) => {
                if (res && res.code === '0') {
                    const nodes = res.data.map((item) => {
                        // 将查询之后的区域对象放到一个map中，给地址code解析地址名称用
                        _this.AREA_MAP.set(item.ebplCode, item.ebplNameCn);
                        return _this.optionsFormat(item, false);
                    });
                    const getNum = _this.leafIndex;
                    this.showRightBtn(getNum);
                    this.tabArr[levNum].dataArr = nodes;
                    if (nodes.length === 0) {
                        this.confirmSuccess();
                    }
                }
            });
        },
        async hotCityClick(quary) {
            const { data } = quary;
            const vindex = String(data.length - 1);
            await this.init(data);
            this.hotData.forEach((item) => {
                if (item.label === quary.label) {
                    item.leaf = true;
                } else {
                    item.leaf = false;
                }
            });
            this.nextLevel(this.hotObj, vindex); // 点击单击热门城市则跳转到所选城市对应的区县级选择页面 ok
        },
        nextLevel(data, name) {
            const tabArr = this.tabArr;
            const newsIndex = Number(name) + 1;
            this.newsIndex = newsIndex;
            const tabItem = tabArr.find(item => item.name === name);
            const tabItemLength = 6;
            if (tabItem) {
                this.setChildrenData(tabItem, data);
                data.leaf = true;
                tabItem.label = data.label.substring(0, tabItemLength);
                // 判断是否存在下一级
                if (newsIndex < this.leafIndex) {
                    this.showbtn = true;
                    this.$set(tabArr, newsIndex, this.listItemObj(newsIndex));
                    this.getLazyLoad(data, newsIndex);
                    this.activeName = (this.hotData.length > 0) ? String(Number(tabArr[newsIndex].name) + 1) : tabArr[newsIndex].name;
                    data.leaf = tabArr[newsIndex].tabPane = true;
                    tabArr[newsIndex].label = this.labelName;
                    tabArr.map((itemSum, indexSum) => {
                        if (indexSum > newsIndex) {
                            itemSum.tabPane = false;
                        }
                    });
                }
                this.addressOptions = this.addressOptions.slice(0, newsIndex - 1);
                this.addressOptions.push(data);
                if (newsIndex >= this.leafIndex) {
                    this.confirmSuccess();
                }
            }
        },
        // 初始化样式
        setChildrenData(item) {
            item.dataArr.forEach(itemKey => {
                itemKey.leaf = false;
            });
        },
        showClick() {
            this.show = true;
            this.showbtn = false;
            this.tabArr = [];
            this.activeName = '0';
            if (!this.value || this.value === '' || this.value.length === 0) {
                this.tabArr.push(this.tabObj);
                this.setChildrenData(this.tabArr[0]);
                this.tabArr[0].label = (this.hotData.length > 0) ? '省份' : '请选择';
                this.getLazyLoad({}, 0);
                if (this.hotData) {
                    this.hotData.forEach((item) => {
                        item.leaf = false;
                    });
                }
            } else {
                this.init(this.value);
            }
        },
        confirmSuccess(status) {
            const strValArr = [];
            const strValLabel = [];
            let getIndex = '';
            const addressOptions = JSON.parse(JSON.stringify(this.addressOptions));
            if (addressOptions.length) {
                if (status === 1 && addressOptions[addressOptions.length - 1].leaf) {
                    getIndex = Number(this.activeName) + 1;
                } else {
                    getIndex = status === 1 ? Number(this.activeName) : Number(this.activeName) + 1;
                }
                addressOptions.slice(0, getIndex).forEach((item) => {
                    strValArr.push(item.value);
                    strValLabel.push(item.label);
                });
                this.inputVal = '';
                this.inputVal = strValLabel.join('/');
                this.changeInput(strValArr);
                this.visible = false;
                this.show = false;
            } else {
                this.$message.warning('请选择地址');
            }
        },
        showRightBtn() {
            // 根据配置是否满足条件
            this.tabArr.map((items, index) => {
                if ((index + 1) >= this.minLayers && this.propsConfig.checkStrictly) {
                    items.sumMeuber = true;
                } else if ((index + 1) > this.minLayers && !this.propsConfig.checkStrictly) {
                    items.tabPane = false;
                } else {
                    items.sumMeuber = false;
                }
            });
        },
        visibleChange() {
            this.visible = false;
            this.inputVal = '';
            this.$emit('input', []);
            this.$emit('getAddress', []);
        },

        // 3 5 7 10 对应省、市、县镇、街截取的长度，处理成如下格式: ['144', '14410']
        handleAddressArr(val) {
            return [PROVINCE, CITY, DISTRICT, COUNTRY].map(item => val.length >= item ? val.substr(0, item) : '').filter(item => item);
        },

        // 处理兼容val可能为['1441010110']、['1441010']
        isAddressArray(val) {
            const provinceLength = 3;
            if (val.length && val[0].length > provinceLength) {
                // val[0]为省code， 一般为三位数，超过3位数则为需要兼容的数据
                // 对错误数据不做处理
                this.addressArr = this.handleAddressArr(val[0]);
            } else {
                this.addressArr = val;
            }
        },

        /**
         * @param {String、Array} value  地址code
         * String： 地址code 如： "1441010"、 '1441010110'
         * Array：省、市、县、镇街的code  如： [144, 10, 10, 110]
         */
        async init(value) {
            this.addressOptions = [];
            this.addressLabel = [];
            // 判断传进来的v-model的值是数组或者字符串
            if (_.isString(value)) {
                // 对传入的字符串进行是省市区哪个级别的处理
                this.addressArr = this.handleAddressArr(value);
            } else if (_.isArray(value)) {
                this.isAddressArray(value);
            }
            // 递归获取默认值options
            await this.getArea(+PROVINCE_DEFAULT_PMCODE, this.addressArr, 0);
        },
        // 获取地址列表
        async getArea(ebplParentPmCode, addressArr, level) {
            this.activeName = (this.hotData.length > 0) ? String(level) : String(level - 1); // 回显打开最后项
            // 根据地址的层级获取数据
            if (!addressArr[level]) return [];
            const res = await getAreaApi({
                ebplParentPmCode,
                ebplType: AREA_TYPE[level]
            }, this.difference);
            if (res && res.code === '0') {
                const nodes = res.data.map(item => {
                    this.AREA_MAP.set(item.ebplCode, item.ebplNameCn);
                    return this.optionsFormat(item, false);
                });
                this.$set(this.tabArr, level, this.listItemObj(level));
                // 获取当前选中的项被获取子地址列表
                const nextNode = nodes.find(item => item.value === addressArr[level]);
                if (nextNode) {
                    this.$set(nextNode, 'leaf', true);
                    this.tabArr[level].dataArr = nodes;
                    this.tabArr[level].label = nextNode.label;
                    this.addressOptions.push(nextNode);
                    this.addressLabel[level] = nextNode.label;
                    this.hotObj = nextNode;
                    if (addressArr.length - 1 === level) {
                        this.inputVal = this.addressLabel.join('/');
                    }
                    await this.getArea(nextNode.value, addressArr, ++level);
                }
                if (nextNode) {
                    const getNum = this.leafIndex;
                    this.showRightBtn(getNum);
                }
                return nodes;
            }
        },
        listItemObj(index) {
            return {
                label: '请选择',
                name: String(index),
                tabPane: true,
                sumMeuber: false,
                dataArr: []
            };
        },
        // 输入框变化监听事件
        changeInput(val) {
            this.$emit('input', val);
            this.$emit('getAddress', this.addressFormat(val));
            if (this.basicProps.checkStrictly) {
                this.visible = false;
                this.show = false;
            }
        },
        addressFormat(arr) {
            let result = [];
            if (_.isArray(arr)) {
                result = arr.map(item => this.AREA_MAP.get(item));
            }
            return result;
        }
    },
    computed: {
        // 判断暂不选择显示
        showNoChoiceBtn() {
            const sunMeuberBtnm = this.showTabItem[((this.hotData.length > 0) ? Number(this.activeName) - 1 : Number(this.activeName)) || 0];
            const state = (sunMeuberBtnm && sunMeuberBtnm.sumMeuber) && this.showbtn && ((Number(this.activeName) + 1) === this.checkActive) && this.showNoChoice;
            return state;
        },
        propsConfig() {
            return { ...this.basicProps };
        },
        leafIndex() {
            return this.propsConfig.checkStrictly ? this.LEAF_INDEX : this.minLayers;
        },
        showTabItem() {
            return this.tabArr.filter(item => item.tabPane);
        },
        icon: function() {
            if (this.show === false) {
                return 'el-icon-arrow-down';
            } else {
                return 'el-icon-arrow-up';
            }
        }
    },
    watch: {
        value: {
            handler(val) {
                if (val) {
                    this.inputVal = '';
                    this.init(val);
                } else {
                    this.addressArr = [];
                    this.inputVal = '';
                    this.$emit('input', []);
                }
            },
            immediate: true
        },
        activeName: {
            handler(val) {
                this.$nextTick(() => {
                    this.checkActive = this.$refs.tabs.panes.length; // 可选总层数
                });
            }
        }
    }
};
</script>
<style lang="less">
.m-address-cascader {
    position: relative;
    .del-input-class {
        .el-input__inner{
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        }
    }
}
.del-popper-class {
    .address-tab-container {
        background: #fff;
        .ulSorll {
            height: 256px;
            overflow-y: auto;
            ul {
                margin: 0 auto;
                display: flex;
                flex-wrap: wrap;
                padding: 0;
            li {
                list-style: none;
                width: 25%;
                font-size: 12px;
                color: #666666;
                cursor: pointer;
                margin-bottom: 4px;
                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 80%;
                    display: inline-block;
                    padding: 2px;
                }
                .active {
                    background: #4285f5;
                        color: #fff;
                }
                .Cactive {
                    color: #4285f5;
                }
                .Cactive:active {
                    background: #4285f5;
                    color: #fff;

                }
            }
        }
     }
    }
    .el-tabs__item {
        padding: 0 16px;
        height: 32px;
        line-height: 32px;
        font-size: 12px;
    }
    .el-tabs__nav-next, .el-tabs__nav-prev {
        line-height: 32px;
    }
}
</style>
