<template>
    <div class="edit-table-box">
        <el-table
                       ref="moduleTable"
            border
            tooltip-effect='dark'
            v-loading="loading"
            :height="height"
            style="width: 100%"
            :data="tableData"
            :row-class-name="tableRowClassName"
            v-bind="attrsExt"
            v-on="$listeners"
            @selection-change="handleSelectionChange"
            @row-click='rowClick'
            @row-dblclick="rowDBclick"
            @sort-change="sortChange">
            <el-table-column v-if="selection" type="selection" width="40" fixed="left"></el-table-column>
          <el-table-column
            v-for="(item, index) in columnsList"
            :show-overflow-tooltip="_.get(item, 'showTooltip', true)"
            :key="index"
            :min-width="_.get(item, 'width', '')"
            :sortable="item.sort" :prop="item.prop"
            :fixed="_.get(item, 'fixed', null)">
            <template v-slot:header>
                                    <span class="must-fill-flag" v-if="item.mustFill">*</span>
                    <!-- !!$scopedSlots['col-header'] 判断插槽是否使用 -->
                <slot name="col-header" v-bind="{ item }" v-if="!!$scopedSlots['col-header']"></slot>
                            <span v-else>{{item.label}}</span>
            </template>
                <template slot-scope="scope">
                    <!-- start：编辑状态 -->
                    <div style="display: flex;" v-if="scope.row.edit !== EDIT_TYPE.CANCEL">
                        <template v-if="!item.type">{{scope.row[item.prop]}}</template>
                        <el-select
                            v-else-if="item.type === 'select'"
                            v-model="scope.row[item.prop]"
                            size='mini'
                            :class="{'error-active': _.get(errorList, `[${scope.$index}][${index}]`)}"
                            :clearable="_.get(item, 'clearable',true)"
                            :filterable="item.filterable"
                            :disabled="_columnIfDisabled(scope.row, item)"
                            :multiple="_.get(item, 'multiple', false)"
                            :placeholder="item.placeholder"
                            :collapse-tags="_.get(item, 'collapseTags', false)"
                            @change="(val) => elementChange(item.prop, val, scope.row, scope.$index)">
                            <el-option
                                v-for="(val, key) in _.get(scope.row, `${item.prop}Options`, item.options)"
                                :key="key"
                                :label="val"
                                :value="key"
                            ></el-option>
                        </el-select>
                        <addressSelect
                            v-else-if="item.type === 'addressSelect'"
                            v-model="scope.row[item.prop]"
                            :class="{'error-active': _.get(errorList, `[${scope.$index}][${index}]`)}"
                            :minLayers="_.get(scope.row, `${item.prop}Layers`,item.minLayers)"
                            :disabled="_columnIfDisabled(scope.row, item)"
                            :basicProps="item.config"
                            @getAddress="labels => getAddress(scope.row, item, labels)"
                            @input="val => elementChange(item.prop, val, scope.row, scope.$index)"
                        ></addressSelect>
                        <el-switch
                            class="edit-table-box_switch"
                            v-else-if="item.type === 'switch'"
                            v-model="scope.row[item.prop]"
                            :disabled="_columnIfDisabled(scope.row, item)"
                            :active-value="_.get(item, 'activeValue', 'Y')"
                            :inactive-value="_.get(item, 'inactiveValue', 'N')"
                            :active-text="item.activeText"
                            :inactive-text="item.inactiveText"
                            @change="(val) => elementChange(item.prop, val, scope.row, scope.$index)"
                        ></el-switch>
                        <lots-md-input
                            v-else-if="item.type === 'advance'"
                            :label="scope.row[item.prop]"
                            :class="{'error-active': _.get(errorList, `[${scope.$index}][${index}]`)}"
                            :disabled="_columnIfDisabled(scope.row, item)"
                            :config="item"
                            :cascadeData="scope.row"
                            @unfindableField="unfindableField"
                            @change="data => handleMdInputChange(data, scope.row, item,scope.$index)"
                        ></lots-md-input>
                        <otpAdvanceMulti
                                v-else-if="item.type === 'advanceMultiple'"
                                :params="item"
                                @getAdvanceValue="(data) => { getMultiInfo(data, scope.row, item,scope.$index); }"
                                :disabled="_columnIfDisabled(scope.row, item)"
                                :mValue="scope.row[item.prop]"
                                :formData="scope.row">
                        </otpAdvanceMulti>
                        <el-date-picker v-else-if="['datetime', 'date', 'week', 'month', 'dates', 'year'].includes(item.type)"
                                        @change="(val) => elementChange(item.prop, val, scope.row, scope.$index)"
                                        size="mini"
                                        v-model="scope.row[item.prop]"
                                        :clearable="_.get(item, 'clearable', true)"
                                        :format="item.format"
                                        :value-format="item.valueFormat"
                                        :type="item.type"
                                        :disabled="_columnIfDisabled(scope.row, item)"
                                        unlink-panels
                                        :placeholder="item.placeholder || $translate('lotsUI.select_date', { defaultText: '选择日期' }) "
                                        :picker-options="_.get(item, 'pickerOptions', null)">
                        </el-date-picker>
                        <el-date-picker v-else-if="(item.type === 'datetimerange') || (item.type === 'daterange')"
                                        @change="(val) => elementChange(item.prop, val, scope.row, scope.$index)"
                                        size="mini"
                                        v-model="scope.row[item.prop]"
                                        :clearable="_.get(item, 'clearable', true)"
                                        :format="item.type === 'datetimerange' ? 'yyyy-MM-dd HH:mm:ss': 'yyyy-MM-dd'"
                                        :value-format="item.type === 'datetimerange' ? 'yyyy-MM-dd HH:mm:ss': 'yyyy-MM-dd'"
                                        :picker-options="_.get(item, 'pickerOptions', pickerOptions)"
                                        :type="item.type"
                                        :disabled="_columnIfDisabled(scope.row, item)"
                                        unlink-panels
                                        :range-separator="$translate('lotsUI.to', { defaultText: '至' })"
                                        :start-placeholder="item.startPlaceholder ||  $translate('lotsUI.start_date', { defaultText: '开始日期' })"
                                        :end-placeholder="item.endPlaceholder || $translate('lotsUI.end_date', { defaultText: '结束日期' })"
                                        :default-time="item.defaultTime || []">
                        </el-date-picker>
                        <address-selector
                            v-else-if="item.type === 'addressSelectNew'"
                            v-model="scope.row[item.prop]"
                            :class="{'error-active': _.get(errorList, `[${scope.$index}][${index}]`)}"
                            :minLayers="_.get(scope.row, `${item.prop}Layers`,item.minLayers)"
                            :disabled="_columnIfDisabled(scope.row, item)"
                            :basicProps="item.config"
                            :showNoChoice="_.get(item, `showNoChoice`, false)"
                            @getAddress="labels => getAddress(scope.row, item, labels)"
                            @input="val => elementChange(item.prop, val, scope.row, scope.$index)"
                        ></address-selector>
                        <slot v-else-if="item.editSlot" name="edit-col-append" v-bind="{ item, row: scope.row, rIndex: scope.$index }"></slot>
                        <el-input
                        v-else
                            :clearable="_.get(item, 'clearable',true)"
                            :placeholder ="_.get(item, 'placeholder','请输入')"
                            v-model="scope.row[item.prop]"
                            size='mini'
                            :disabled="_columnIfDisabled(scope.row, item)"
                            :class="{'error-active': _.get(errorList, `[${scope.$index}][${index}]`)}"
                            @input="(val) => elementChange(item.prop, val, scope.row, scope.$index)"
                            @blur="(event) => {
                                inputBlur(item.prop, event.target.value, scope.row, scope.$index);
                            }"
                        ></el-input>
                    </div>
                    <!-- start：非编辑状态 -->
                    <template v-else>
                        <el-switch
                            v-if="item.type === 'switch'"
                            class="edit-table-box_switch" v-model="scope.row[item.prop]"
                            disabled
                            :active-value="_.get(item, 'activeValue', 'Y')"
                            :inactive-value="_.get(item, 'inactiveValue', 'N')"
                            :active-text="item.activeText"
                            :inactive-text="item.inactiveText"
                        ></el-switch>
                        <slot v-else-if="item.slot" name="col-append" v-bind="{ item, row: scope.row, rIndex: scope.$index }">
                        </slot>
                        <template v-else>
                            {{dataFormat(scope.row, item)}}
                        </template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column
                :label="$translate('component.E202006152352',{defaultText:'操作'})"
                fixed="right"
                :width="actionColWidth"
                v-if="control===true">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" v-if="scope.row.edit === EDIT_TYPE.CANCEL && scope.row.allowEdit"
                        :disabled="editDisableRule(scope.row)"
                        @click.stop="btnEvent(scope.row,'edit')">{{$translate('component.E202006152412',{defaultText:'编辑'})}}
                    </el-button>
                    <el-button size="mini" type="text" v-else-if="scope.row.edit !== EDIT_TYPE.CANCEL && scope.row.allowEdit"
                        @click.stop="btnEvent(scope.row, 'cancel', scope.$index)">{{$translate('component.E202006152345',{defaultText:'取消'})}}</el-button>
                    <template v-for="(e, eIndex) in actions.list">
                        <el-button v-if="e.displayRule(scope.row, EDIT_TYPE)" type="text" size="mini" :key="eIndex"
                                   @click.stop="btnEvent(scope.row, e.event, scope.$index)" :loading="scope.row[`${e.event}Loading`]">{{e.label}}
                        </el-button>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <advance-dialog ref="advanceDialog"></advance-dialog>
    </div>
</template>
<script>
import _ from 'lodash';
import { dictWithUrl } from '../api';
import addressSelect from '@/components/lots/otpCascader2';
import advanceDialog from '@/components/lots/otpAdvanceDialog';
import lotsMdInput from '@/components/lots/lotsMdInput';
import addressSelector from '@/components/lots/addressSelector/index';
import otpAdvanceMulti from '@/components/lots/otpAdvanceMulti/index.vue';
import { I18nMixins } from '@/components/mixins/i18n';
import config from './config';
const HEIGHTOFFSET = 210;
/**
 * @module lots/EditTableBox
 * @desc 列配置组件
 * @vue-prop {Boolean} selection - 是否需要复选框
 * @vue-prop {Number} height - 表格的高度，不传则动态计算
 * @vue-prop {Array.<Object>} columns - 表格列数据
 * @vue-prop {Array.<Object>} tableData - 表格数据
 * @vue-prop {Boolean} loading - 数据加载时是否显示loading
 * @vue-prop {String} customColumnProps - 自定义的列字符串，格式如：xxx1,xxx2,...
 * @vue-prop {Boolean} control true 是否需要操作列
 * @vue-prop {Array.<Object>} actions - 操作列按钮配置数组
 * @vue-prop {Array.<Object>} initColumns - 默认的列配置
 * @vue-prop {Boolean} allowRowClick - 是否允许单击行进入编辑状态
 * @vue-prop {Boolean} banMultiRowEditForRowClick - 是否允许多行同时编辑
 * @vue-data {Boolean} dialogVisible - 组件弹窗显示控制符
 * @vue-data {enum} EDIT_TYPE - 编辑状态的枚举值
 * @vue-data {Array.<Object>} selectedRowsData - 选中的行数组
 * @vue-data {Array.<Object>} errorList - 错误信息列表
 * @vue-data {Array.<Object>} columnsList - 列配置数组
 * @vue-data {Object} keyMap - 存放高级弹窗组件的值映射
 * @vue-data {Function} dataFormat - 默认的数据转换函数
 * @vue-event {*} btn-event - 操作栏按钮点击事件
 * @vue-event {*} element-change - 单元格属性变化事件
 * @vue-event {*} selection-change - 复选框变化事件
 * @vue-event {*} row-db-click - 行双击事件
 * @vue-event {*} header-change - 返回配置好的列数据
 */
export default {
    mixins: [I18nMixins, config],
    name: 'EditTableBox',
    data() {
        return {
            EDIT_TYPE: { EDIT: 2, CANCEL: -3 },
            selectedRowsData: [],
            errorList: [],
            columnsList: [],
            keyMap: {},
            dataFormat: (row, col) => row[col.prop],
            pickerOptions: config.pickerOptions
        };
    },
    props: config.props,
    components: { addressSelect, advanceDialog, lotsMdInput, addressSelector, otpAdvanceMulti },
    methods: {
        /**
            * @description 输出排序事件
        */
        sortChange(val) {
            this.$emit('sort-change', { ...val });
        },
        unfindableField(val) {
            this.$emit('unfindableField', val);
        },
        tableRowClassName ({ row }) {
            if (this.rowClassName) {
                if (this.selectedRowsData && this.selectedRowsData.includes(row)) {
                    return 'highlight-row';
                }
            }
        },
        addRow() {
            // 新增一行，并进入行内编辑模式
            const obj = {};
            this.columnsList.forEach(item => {
                // obj[item.prop] = item.defaultValue || '';
                obj[item.prop] = _.get(item, 'defaultValue', '');
            });
            obj.edit = this.EDIT_TYPE.EDIT;
            this.tableData.unshift(obj);
            // 新增默认勾选
            this.$refs.moduleTable.toggleRowSelection(obj, true);
        },
        elementChange(prop, val, row, index) {
            this.$emit('element-change', {
                prop,
                val,
                row,
                index
            });
        },
        handleSelectionChange(selection) {
            this.selectedRowsData = this.getTable().selection;
            this.$emit('selection-change', selection);
        },
        rowDBclick(row) {
            this.$emit('row-db-click', row);
        },
        btnEvent(selection, event, index) {
            if (event === 'edit' || event === 'cancel') {
                // 状态取反
                selection.edit = ~selection.edit;
                if (selection.edit === this.EDIT_TYPE.CANCEL) {
                    if (!selection.id) {
                        this.tableData.splice(selection.serialNum, 1);
                    } else if (selection.backup) {
                        this.$set(this.tableData, selection.serialNum, selection.backup);
                        this.$set(this.tableData[selection.serialNum], 'edit', this.EDIT_TYPE.CANCEL);
                    }
                } else {
                    selection.backup = _.cloneDeep(selection);
                    this.$refs.moduleTable.toggleRowSelection(selection, true);
                }
            }
            this.$emit('btn-event', selection, event, index);
        },
        rowClick(rows) {
            if (this.allowRowClick && !this.editDisableRule(rows)) {
                if (rows.edit === this.EDIT_TYPE.EDIT) {
                    return;
                }
                if (this.banMultiRowEditForRowClick) {
                    this.tableData.forEach(item => {
                        item.edit = this.EDIT_TYPE.CANCEL;
                    });
                }
                this.tableData[rows.serialNum].edit = this.EDIT_TYPE.EDIT;
                rows.backup = _.cloneDeep(rows);
                if (rows) {
                    this.initRowBg(rows.serialNum);
                    this.$refs.moduleTable.toggleRowSelection(rows, true);
                }
            }
            this.$emit('row-click', rows);
        },
        // 提供给外部使用，验证表单字段（单条数据）
        saveItemData(data) {
            let ModifyData = _.cloneDeep([data]).map(item => {
                delete item.backup;
                delete item.edit;
                return item;
            });
            if (!this.tableExamine(ModifyData)) {
                ModifyData = false;
            }
            return ModifyData;
        },
        // 提供给外部使用，验证表单字段（勾选中的数据）
        saveData() {
            let ModifyData = _.cloneDeep(this.selectedRowsData).map(item => {
                delete item.backup;
                delete item.edit;
                return item;
            });
            if (!this.tableExamine(ModifyData)) {
                ModifyData = false;
            }
            return ModifyData;
        },
        // 表格提交验证
        tableExamine(data) {
            let errorHint = false;
            let errorMsg = '';
            // 重置错误提醒对象
            this.errorList = this.tableData.map(item => {
                return Array(Object.keys(item).length).fill(false);
            });
            data.forEach(data => {
                this.columnsList.forEach((column, index) => {
                    const propIsNecessary = data.notNecessaryProps ? !data.notNecessaryProps.includes(column.prop) : column.mustFill;
                    const notNull = data[column.prop] !== undefined && data[column.prop] !== null && data[column.prop] !== '';
                    if (propIsNecessary) {
                        const errorParams = this.isNecessary(notNull, column, data, errorHint, errorMsg, index);
                        errorHint = errorParams.errorHint;
                        errorMsg = errorParams.errorMsg;
                    }
                    if (notNull && column.verify && !RegExp(column.verify).test(data[column.prop])) {
                        this.errorList[data.serialNum][index] = errorHint = true;
                        errorMsg = errorMsg === ''
                            ? (column.errorMsg ? column.errorMsg : '表格数据部分选项格式不正确')
                            : errorMsg;
                    }
                });
            });
            if (errorHint) {
                this.$message.error(errorMsg);
            }
            return !errorHint;
        },
        isNecessary(notNull, column, data, errorHint, errorMsg, index) {
            if (notNull) {
                if (_.isArray(data[column.prop]) && data[column.prop].length === 0) {
                    this.errorList[data.serialNum][index] = errorHint = true;
                    errorMsg = errorMsg === '' ? '表格数据必填项不能为空' : errorMsg;
                }
            } else {
                this.errorList[data.serialNum][index] = errorHint = true;
                errorMsg = errorMsg === '' ? '表格数据必填项不能为空' : errorMsg;
            }
            return {
                errorMsg,
                errorHint
            };
        },
        detailSearch(row, data, item, type, searchData) {
            if (!searchData) {
                searchData = '';
            }
            //  当前操作的行
            const currentOperateRow = this.tableData[row];
            const advanceCascade = {};
            // 高级搜索级联操作，current.advanceCascade为需要级联的参数的key的集合，例如advanceCascade: key1,key2,key3
            if (item && _.isString(item.advanceCascade)) {
                let advanceArray = [];
                advanceArray = item.advanceCascade.split(',');
                advanceArray.map((item) => {
                    let { 0: sourceProp, 1: destProp } = item.split('#');
                    destProp = destProp || sourceProp;
                    advanceCascade[destProp] = this.tableData[row][sourceProp];
                });
            }
            this.$refs.advanceDialog.show(true, item, advanceCascade);
            this.$refs.advanceDialog.$once('confirm', (result) => {
                // 将返回数据添加到map里，用于高级搜索清空时，顺带清空其他关联属性
                const keyArr = Object.keys(result);
                if (keyArr.length > 0) {
                    keyArr.forEach(key => {
                        this.keyMap[key] = keyArr;
                    });
                }
                Object.assign(currentOperateRow, result);
                this.$emit('element-change', { val: _.cloneDeep(result), type: 'advance', row: currentOperateRow });
            });
        },
        dataInit(tableData) {
            const list = tableData || this.tableData;
            list.map((item, index) => {
                item.serialNum = index;
                if (item.id && !item.edit) {
                    this.$set(this.tableData[index], 'edit', this.EDIT_TYPE.CANCEL);
                }
                const errList = [];
                for (let i = 0; i < Object.keys(item).length; i++) {
                    errList.push(false);
                }
                this.$set(this.errorList, index, errList);
            });
            // 初始化keyMap
            this.columnsList.forEach(data => {
                if (data.type === 'advance' && _.isArray(data.cbParams)) {
                    const keys = data.cbParams.map(item => {
                        const arr = item.split('#');
                        return arr[1] || arr[0];
                    });
                    keys.forEach(key => {
                        this.keyMap[key] = keys;
                    });
                }
            });
            this.initRowBg();
            this.$refs.moduleTable.doLayout();
        },
        // 初始化行背景，并高亮指定行
        initRowBg(rowIndex = null) {
            const arr = document.getElementsByClassName('el-table__row');
            for (let i = 0; i < arr.length; i++) {
                arr[i].style.background = '';
            }
            if (rowIndex) {
                arr[rowIndex].style.background = '#F1F8FA';
            }
            return arr;
        },
        // 转化中文地址数组成连续字符串
        getAddress(row, item, labels) {
            // 判断是否为二维数组。如果是，则说明为多选地址，否则为单选地址
            if (_.isArray(labels) && _.isArray(labels[0])) {
                const labelCnArr = labels.map(item => {
                    return item.join(' / ');
                });
                row[item.labelCn] = labelCnArr.join(',');
            } else {
                row[item.labelCn] = labels.join(' / ');
            }
        },
        // 高级输入框的关闭按钮点击
        resetAdvanceParams(prop, row) {
            const keyArr = this.keyMap[prop];
            // 把属性相关联的字段置空
            _.isArray(keyArr) && keyArr.forEach(key => {
                row[key] = '';
            });
        },
        getTable() {
            return this.$refs['moduleTable'];
        },
        handleMdInputChange(data, row, item, index) {
            // Object.assign(row, data); // 此方式会有渲染bug，数据变化表格不能实时渲染，改用$set
            Object.keys(data).forEach(key => {
                this.$set(row, key, data[key]);
            });
            this.$emit('element-change', { prop: item.prop, val: _.cloneDeep(data), type: 'advance', row, index });
        },
        inputBlur(prop, val, row, index) {
            this.$emit('input-blur', { prop, val, type: 'input-blur', row, index });
        },
        /**
         * 多选弹窗回调获取信息
         */
        getMultiInfo(data, row, item, index) {
            row[item.prop] = data;
            this.$emit('element-change', { prop: item.prop, val: _.cloneDeep(data), type: 'advanceMultiple', row, index });
        }
    },
    watch: {
        actions: {
            handler(val) {
                if (val.list) {
                    _.forEach(val.list, ele => {
                        if (!_.isFunction(ele.displayRule)) {
                            ele.displayRule = () => true;
                        }
                    });
                }
            },
            immediate: true
        },
        tableData: function (val) {
            this.dataInit(val);
        },
        columns: {
            handler: function (arr) {
                this.columnsList = arr;
            },
            immediate: true
        },
        customColumnProps: function (list) {
            const datas = list.split(',');
            const columnsList = [];
            datas.forEach(prop => {
                this.columns.forEach(data => {
                    if (data.prop === prop) {
                        columnsList.push(data);
                    }
                });
            });
            this.columnsList = columnsList;
        }
    },
    computed: {
        _() {
            return _;
        },
        actionColWidth() {
            const btnWidth = 50;
            return this.actions.fixedWidth || btnWidth * this.actions.list.length;
        },
        attrsExt() {
            const result = _.cloneDeep(this.$attrs);
            if (!_.has(result, 'max-height') && !_.has(result, 'height')) {
                result.height = document.body.clientHeight - HEIGHTOFFSET;
            }
            return result;
        },
        _columnIfDisabled() {
            function getDisabled(row, col) {
                return _.isArray(row.disableProps) ? row.disableProps.includes(col.prop) : col.disabled;
            };
            return getDisabled;
        }
    },
    created() {
        // 表格渲染前先判断表格列中使用到的数据字典是否都在浏览器端有备份，没有的进行接口获取，保证表格的字典转义
        const keys = _.chain(this.columns).map(obj => obj.optionsKey).filter(str => !_.isNil(str)).value();
        const selectCols = _.chain(this.columns).filter(obj => obj.optionsKey).value();
        if (keys.length > 0) {
            dictWithUrl(keys.join(',')).then(obj => {
                if (obj.code === '0') {
                    const data = obj.data;
                    // 初始化下拉框的数据字典
                    selectCols.forEach(val => {
                        val.options = _.get(data, val.optionsKey);
                    });
                    // 给转义函数重新赋值，触发重新渲染
                    this.dataFormat = (row, col) => {
                        // 为空，为0，为空数组时返回''
                        if ((!row[col.prop] && row[col.prop] !== 0) || (_.isArray(row[col.prop]) && row[col.prop].length === 0)) {
                            return '';
                        }
                        // 下拉框变为正常时显示值的改变
                        let result = null;
                        if (col.type === 'select') {
                            const keyMaps = col.options;
                            if (_.isArray(row[col.prop])) {
                                result = row[col.prop].map(val => {
                                    val = val + '';
                                    return _.get(keyMaps, val, val);
                                }).join(',');
                            } else {
                                const value = row[col.prop] + '';
                                result = _.get(keyMaps, value, value);
                            }
                        } else if (col.type === 'addressSelect' || col.type === 'addressSelectNew') {
                            result = row[col.labelCn];
                        } else {
                            result = row[col.prop];
                        }
                        return result;
                    };
                }
            });
        }
    }
};
</script>
<style lang="less">
.edit-table-box {
    .el-table__body tr.highlight-row {
        background: #E1E7EF;
    }

    .error-active input {
        border-color: red;
    }

    .error-active .otpAllAddressSelect .el-cascader .el-input {
        border-color: red;
    }

    /*打开时文字位置设置*/

    .edit-table-box_switch .el-switch__label--right {
        z-index: 1;
        right: -10px;
    }

    /*关闭时文字位置设置*/

    .edit-table-box_switch .el-switch__label--left {
        z-index: 1;
        left: 26px;
    }

    /*显示文字*/

    .edit-table-box_switch .el-switch__label.is-active {
        display: block;
    }

    .must-fill-flag {
        // font-size: 14px;
        color: #f25555;
        margin-right: 2px;
    }
    .toptip:hover{
        border-top-color: #409EFF;
    }
    .bottomtip:hover{
        border-bottom-color: #409EFF;
    }
}
</style>
