
const AN_HOUR_SECOND = 3600;
const ONE_MONTH = 30;
const THREE_MONTH = 90;
export default {
    pickerOptions: {
        shortcuts: [{
            text: '最近一周',
            onClick: (picker) => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - AN_HOUR_SECOND * 1000 * 24 * 7);
                picker.$emit('pick', [start, end]);
            }
        }, {
            text: '最近一个月',
            onClick: (picker) => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - AN_HOUR_SECOND * 1000 * 24 * ONE_MONTH);
                picker.$emit('pick', [start, end]);
            }
        }, {
            text: '最近三个月',
            onClick: (picker) => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - AN_HOUR_SECOND * 1000 * 24 * THREE_MONTH);
                picker.$emit('pick', [start, end]);
            }
        }]
    },
    props: {
        selection: {
            type: Boolean,
            default() {
                return true;
            }
        },
        height: {
            type: [Number, String],
            default() {
                const heightOffset = 160;
                const $el = document.querySelector('.app-main');
                if (!$el) return null;
                const tableHeight = $el.getBoundingClientRect().height - heightOffset;
                return tableHeight;
            }
        },
        columns: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        },
        tableData: {
            type: Array,
            default() {
                return [];
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        },
        customColumnProps: {
            type: String,
            default() {
                return '';
            }
        },
        control: {
            type: Boolean,
            default() {
                return true;
            }
        },
        actions: {
            type: Object,
            default() {
                return {
                    list: []
                };
            },
            validator: function (obj) {
                return !!obj.list;
            }
        },
        allowRowClick: {
            type: Boolean,
            default() {
                return true;
            }
        },
        banMultiRowEditForRowClick: {
            type: Boolean,
            default() {
                return true;
            }
        },
        editDisableRule: {
            type: Function,
            default() {
                return false;
            }
        }
    }
};
